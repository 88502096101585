import { Link } from 'react-scroll';

const ListMenu = () => {
    return(
        <>
            <li><Link activeClass="active" smooth spy to="Penafian">PENAFIAN/SANGGAHAN HUKUM</Link></li>
            <li><Link activeClass="active" smooth spy to="Komunitas">Komunitas Sepak Bola dan Crypto Asset di Indonesia
</Link></li>
            <li><Link activeClass="active" smooth spy to="ApaituPT">Apa itu PT Transaksi Sistem Digital</Link></li>
            <li><Link activeClass="active" smooth spy to="ApaituCST">Apa itu CST</Link></li>
            <li><Link activeClass="active" smooth spy to="TeknologiInti">Teknologi Inti</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="standartkoinDWA">Standart koin DWA</Link></li>
                <li><Link activeClass="active" smooth spy to="DWAMengadopsi">Kriptografi Curve25519</Link></li>
                <li><Link activeClass="active" smooth spy to="PrinsipDasar">Prinsip Dasar</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="Maksuddantujuan">Maksud dan Tujuan</Link></li>
            <li><Link activeClass="active" smooth spy to="Mekanismealur">Mekanisme alur/use case</Link></li>
            <ul>
                <li><Link activeClass="active" smooth spy to="Padatahap1">Pada tahap 1</Link></li>
                <li><Link activeClass="active" smooth spy to="Padatahap2">Pada Tahap 2</Link></li>
                <li><Link activeClass="active" smooth spy to="Padatahap3">Pada Tahap 3</Link></li>
                <li><Link activeClass="active" smooth spy to="Padatahap4">Pada Tahap 4</Link></li>
                <li><Link activeClass="active" smooth spy to="Padatahap5">Pada Tahap 5</Link></li>
            </ul>
            <li><Link activeClass="active" smooth spy to="TOKENOMIC">TOKENOMIC</Link></li>
            <li><Link activeClass="active" smooth spy to="Roadmap">Roadmap</Link></li>
            <li><Link activeClass="active" smooth spy to="TeamKami">Team Kami</Link></li>
            <li><Link activeClass="active" smooth spy to="PartnerKami">Partner Kami</Link></li>
            
        </>
    );
}

export default ListMenu;