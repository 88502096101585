import { useState } from 'react';

import ListMenu from '../../container/ListMenu';

const Home = () => {
    const [menu, setMenu] = useState(false);
    const showMenu = () => {
        if (menu) {
            setMenu(false);
        } else {
            setMenu(true);
        }
    }

    const hideMenu = () => {
        setMenu(false);
    }
    const imageClick = () => {
        fetch(process.env.PUBLIC_URL+'/CST Whitepaper.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'CST WhitePaper.pdf';
                alink.click();
            })
        })
     }
    return (
        <>
            <div className="container">
                <nav className="navbars p-3 border-bottom">
                    <div className="container-fluid">
                        <div className="navbar-brands">
                            <img src={process.env.PUBLIC_URL + '/images/CSTLOGO.png'} alt="CSTLOGO.png" />
                        </div>
                        {/* <form className="d-flex" role="search">
                    <div id="google_translate_element"></div>
                </form> */}
                    </div>
                </nav>
                <div className="p-4">
                    <div className="btn-group mb-3">
                        <button class="btn btn-secondary dropdown-toggle w-100" type="button" onClick={showMenu}>
                            On This Page
                        </button>
                        <ul className={menu ? 'dropdown-menu p-2 show' : 'dropdown-menu p-2'}>
                            <ListMenu />
                        </ul>
                    </div>
                    <div className="row" style={{ marginBottom: 800 }} onClick={hideMenu}>
                        <div className="col-md-8">
                            
                            <section id="Penafian">
                            <img style={{ width: 200, height: 50 }}  src={process.env.PUBLIC_URL + '/images/download-pdf.webp'} onClick={imageClick}  alt="download.png" />
                            <br></br>
                            <br></br>
                            <h1 style={{ textAlign: 'justify' }}>WHITEPAPER</h1>
        
                            <p>Pembaruan Terakhir 6 January 2023</p>
                            <h4 style={{ textAlign: 'justify' }}>Tautan yang digunakan</h4>
                            <ul>
                                <li><a href="https://csttoken.net" target="_blank" > https://csttoken.net</a></li>
                                <li><a href="https://whitepaper.csttoken.net" target="_blank" > https://whitepaper.csttoken.net</a></li>
                                <li><a href="https://csttoken.net/explorer" target="_blank" >https://csttoken.net/explorer</a></li>
                                <li><a href="https://wallet.csttoken.net" target="_blank" >https://wallet.csttoken.net</a></li>
                            </ul>

                            <h6 style={{ textAlign: 'justify' }}>PENAFIAN/SANGGAHAN HUKUM</h6>

                            <p style={{ textAlign: 'justify' }}>
                                Tidak ada dalam Whitepaper ini yang merupakan tawaran untuk menjual, atau ajakan untuk membeli, koin atau token apa pun. CST menerbitkan Whitepaper ini semata-mata untuk menerima umpan balik dan komentar dari publik. Jika dan ketika CST menawarkan untuk dijual koin atau token apa pun (atau Perjanjian Sederhana untuk Koin atau Token Masa Depan), CST akan melakukannya melalui dokumen penawaran definitif, termasuk dokumen pengungkapan dan faktor risiko. Dokumen definitif tersebut juga diharapkan menyertakan versi terbaru dari Whitepaper ini, yang mungkin berbeda secara signifikan dari versi saat ini. Jika dan ketika CST membuat penawaran semacam itu di Indonesia dan secara internasional, penawaran tersebut kemungkinan akan tersedia hanya untuk investor terakreditasi.
                            </p>
                            <p style={{ textAlign: 'justify' }}>

                                Tidak ada dalam Whitepaper ini yang harus diperlakukan atau dibaca sebagai jaminan atau janji tentang bagaimana bisnis CST token akan berkembang atau utilitas atau nilai token. Whitepaper ini menguraikan rencana saat ini, yang dapat berubah sesuai kebijakannya, dan keberhasilannya akan bergantung pada banyak faktor di luar kendali CST, termasuk faktor berbasis pasar dan faktor dalam industri data dan crypto asset. Setiap pernyataan tentang peristiwa masa depan hanya didasarkan pada analisis CST tentang masalah yang dijelaskan dalam Whitepaper ini. Analisis itu mungkin terbukti salah.

                            </p>
                            </section>
                            <ol>
                                <section id="Komunitas">
                                <li className="text-justify pr-50"><b>Komunitas Sepak Bola dan Crypto Asset di Indonesia</b>
                                    <p>Indonesia masuk dalam jajaran salah satu negara penggemar sepak bola terbanyak di dunia. Berdasarkan survei dari Repucom Indonesia (telah berganti menjadi Nielsen Sports) menempati urutan kedua sebagai negara yang paling mencintai sepak bola. Sebanyak 77 persen penduduk Indonesia menggemari sepak bola.</p>
                                    <img src={require("../../image/diagram TSD.png")} />
                                    <p>Sementara, Indonesia berada di urutan ketujuh dengan basis pengguna kripto lebih dari 7,4 juta pengguna. Itu berarti hanya 2,7% penduduk Indonesia yang memiliki crypto asset. Untuk itu integrasi dasar  komunitas bola dengan kripto memiliki peluang yang besar untuk perkembangan CST dikarenakan semua komunitas bola di Indonesia belum memiliki pengukuran penghargaan/reward yang akan diberikan kepada supporter bola tersebut.</p>
                                    <p>Hal ini juga sejalan dengan maksud dan tujuan CST yang tertera dibawah untuk mengembangkan lebih banyak lingkungan stabletoken.</p>
                                </li>
                                </section>
                                <section id="ApaituPT">
                                <li className="text-justify pr-50"><b>Apa itu PT Transaksi Sistem Digital</b>
                                    <p>PT Transaksi Sistem Digital (untuk selanjutnya disebut sebagai “TSD”) merupakan perusahaan yang didirikan berdasarkan akta pendirian no. 11 tertanggal 06 September 2022 dan telah mendapatkan pengesahan oleh Kementerian Hukum dan Hak Asasi Manusia no. AHU-0061143.AH.01.01.TAHUN 2022.</p>
                                    <p>Terbentuknya perusahaan ini didasarkan atas mendukung kemajuan tekknologi kripto di Indonesia, menciptakan ekosistem yang menunjang kebutuhan dalam perkembangan teknologi pada masyarakat Indonesia, sehingga teknologi ini dapat digunakan oleh masyarakat luas.</p>
                                </li>
                                </section>
                                <section id="ApaituCST">
                                <li className="text-justify pr-50"><b>Apa Itu CST</b> Kunjungi <a href="https://csttoken.net/" target="_blank" >https://csttoken.net/</a>
                                    <p>CST adalah sebuah token dari teknologi blockchain. Setiap transaksi yang terkait dengan pembelian, penjualan, transfer, penukaran token, dll akan dikenakan biaya tertentu dalam bentuk token. Biaya akan disesuaikan berdasarkan jenis transaksi dan beberapa properti transaksi lainnya yang ditetapkan pada saat pembuatan transaksi.</p>
                                    <p>CST adalah Crypto Sustainable Token yang diluncurkan pada Agustus 2022. Token CST adalah crypto asset bukti kepemilikan dengan jumlah total supply 100.000.000.000,0000 token (seratus miliar token). Nilai tukar token pada CST disesuaikan menjadi 1 CST : Rp 10.000,00 (sepuluh ribu Rupiah).</p>
                                    <p>CST merupakan stabletoken yang berfungsi memberikan alternatif atas volatilitas tinggi dari salah satu crypto asset yang paling terkenal yakni BITCOIN (BTC) atau mempertahankan nilai tukarnya dengan didukung oleh cadangan crypto asset lainnya </p>
                                </li>
                                </section>   
                                    <ol>
                                        <li>Ekosistem CST sebagai aset digital atau virtualnya. CST dapat digunakan untuk hal-hal berikut:
                                            <ol>
                                                <li>
                                                    Sebagai jembatan untuk mendapatkan Point Reward dari koordinator komunitas ;
                                                </li>
                                                <li>
                                                    Menciptakan aset yang mewakili obligasi atau kepemilikan;
                                                </li>
                                                <li>
                                                    Menyimpan aset dalam bentuk CST tanpa harus menukarnya ke mata uang flat hingga berisiko kehilangan momentum melakukan transaksi saat volatilitas yang sedang tinggi.
                                                </li>
                                            </ol>
                                        </li>
                                        <li>	Keunggulan CST, yakni:
                                            <ol>
                                                <li>
                                                    CST merupakan stabletoken yang memberikan kepastian nilai tukar;
                                                </li>
                                                <li>
                                                    CST merupakan turunan dari koin DWA yang mengutamakan kesederhanaan teknologi dalam integrasinya nya. Sehingga sangat mendukung  komunitas dalam pengembangan nya sendiri;
                                                </li>
                                                <li>
                                                    CST berada pada blockchain DWA yang memprioritaskan keamanan system blockchain itu sendiri. Dimana balance token akun – akun yang baru akan di alokasikan pada “guaranteed balance” selama 1440 blocks atau 24 jam.
                                                </li>
                                                <li>
                                                    CST memiliki visi misi menjadi stabletoken yang menjadi acuan dalam penggunaan pada ekosistem blockchain.
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <section id="TeknologiInti">
                                <li className="text-justify pr-50"><b>	Teknologi Inti</b>
                                    <ol>
                                        <section id="standartkoinDWA">
                                        <li>Standart Koin DWA
                                            <ol>
                                                <p>Koin DWA Koin memiliki algoritma proof-of-stake yang unik dengan cara desain yang berbeda dibandingkan dengan implementasi konsep zaman koin yang digunakan oleh crypto asset proof-of-stake lainnya dan tahan terhadap apa yang disebut nothing at stake attacks. Kriptografi Curve25519 digunakan untuk memberikan keseimbangan keamanan dan kekuatan pemrosesan yang diperlukan, bersama dengan penggunaan algoritma hashing SHA256</p>
                                                <p>Kami tidak akan menjelaskan secara detil terkait keseluruhan DWA, hal itu dapat dilihat pada</p>
                                                <ul>
                                                    <li>What is DWA? at <a href="https://dwa.network/" target="_blank" > https://dwa.network/</a></li>
                                                    <li>DWA Whitepaper <a href="https://whitepaper.dwa.network/" target="_blank" > https://whitepaper.dwa.network/</a></li>
                                                    <li>DWA API<a href="https://api.dwa.network/#introduction" target="_blank" > https://api.dwa.network/#introduction</a></li>
                                                    <li>DWA repository <a href="https://github.com/dwacoin/dwa" target="_blank" > https://github.com/dwacoin/dwa</a></li>
                                                    <li>DWA explorer <a href="https://explorer.dwa.network/" target="_blank" >https://explorer.dwa.network/</a></li>
                                                </ul>
                                                <br></br>

                                                <ul>
                                                    <li>CST Explorer <a href="https://csttoken.net/explorer" target="_blank" >https://csttoken.net/explorer</a></li>

                                                    <li>CST Wallet <a href="https://wallet.csttoken.net" target="_blank" > https://wallet.csttoken.net</a></li>
                                                </ul>

                                            </ol>
                                        </li>
                                        </section>
                                        <section id="DWAMengadopsi">
                                        <li>	DWA mengadopsi kriptografi Curve25519 untuk memberikan keseimbangan keamanan dan kekuatan pemrosesan yang diperlukan, bersama dengan penggunaan algoritma hashing SHA256.
                                            <p>Seiring dengan model Proof of Stake yang digunakan oleh koin DWA, keamanan jaringan diatur oleh rekan-rekan yang memiliki kepentingan dalam jaringan. Insentif yang diberikan oleh algoritme ini tidak mendorong sentralisasi dengan cara yang sama seperti yang dilakukan algoritme Proof of Work, dan data menunjukkan bahwa jaringan koin DWA tetap sangat terdesentralisasi sejak awal: sejumlah besar akun unik berkontribusi memblokir jaringan, dan lima akun teratas telah menghasilkan 42% dari total jumlah blok.
                                            </p>
                                        </li>
                                        </section>
                                        <section id="PrinsipDasar">
                                        <li>	Prinsip dasar berikut berlaku untuk algoritma DWA Proof of Stake yaitu:
                                            <ul>
                                                <li>
                                                    Nilai kesulitan kumulatif disimpan sebagai parameter di setiap blok, dan tingkat kesulitan baru diterapkan ke setiap blok berikutnya berdasarkan nilai blok sebelumnya. Dalam kasus ambiguitas, jaringan mencapai konsensus dengan memilih blok atau fragmen rantai dengan kesulitan kumulatif tertinggi;
                                                </li>
                                                <li>
                                                    Token harus stasioner dalam akun untuk 1.440 blok sebelum mereka dapat berkontribusi pada proses pembuatan blok, mekanisme ini untuk mencegah pemegang akun memindahkan saham mereka dari satu akun ke akun lain dengan tujuan memanipulasi kemungkinan pembuatan blok mereka. Token yang memenuhi kriteria ini berkontribusi pada saldo efektif akun, dan saldo ini digunakan untuk menentukan probabilitas penempaan;
                                                </li>
                                                <li>
                                                    Peer memungkinkan reorganisasi rantai tidak lebih dari 720 blok di belakang ketinggian blok saat ini. Ini bertujuan untuk mencegah penyerang membuat rantai baru sepenuhnya dari blok genesis, setiap blok yang dikirimkan pada ketinggian lebih rendah dari ambang batas ini ditolak; dan
                                                </li>
                                                <li>
                                                    Transaksi dianggap aman setelah dikodekan ke dalam blok yang berjarak 10 blok di belakang ketinggian blok saat ini. Ini karena probabilitas yang sangat rendah dari akun mana pun yang mengendalikan blockchain dengan menghasilkan rantai bloknya sendiri.
                                                </li>
                                            </ul>
                                        </li>
                                        </section>
                                    </ol>
                                </li>
                                </section>
                                <section id="Maksuddantujuan">
                                <li className="text-justify pr-50"><b>	Maksud dan Tujuan</b>
                                    <p>CST bisnis model memfasilitasi komunitas sepakbola dengan menjembatani klub sepak bola dan supporter nya. Loyalitas supporter dapat di representasikan dalam bentuk penyimpanan token CST di klub sepak bola masing-masing. Dan sebagai timbal balik nya, klub sepak bola memberikan reward ataupun point – point yang nantinya dapat ditukarkan dengan merchandise ataupun tiket pertandingan.
                                    </p>
                                    <p>
                                        Penyimpanan token CST di klub sepak bola akan kami berikan fitur bernama GAWANG yakni tempat untuk melakukan lock-up/hold CST untuk mendapatkan point reward.
                                    </p>
                                </li>
                                </section>
                                <section id="Mekanismealur">
                                <li className="text-justify pr-50"><b>	Mekanisme Alur /Use Case</b>
                                    <img src={require("../../image/usecase.png")} />
                                    <p>
                                        Model bisnis dalam diagram diatas menjelaskan mengenai bagaimana komunitas memperoleh point reward yang dapat dilakukan redeem terhadap hadiah-hadiah yang akan diberikan oleh klub bola terkait.
                                    </p>
                                    <p>
                                        Tahapan-tahapan berupa:
                                    </p>
                                    <section id="Padatahap1">
                                    <p>
                                        <b>
                                            Pada Tahap 1
                                        </b>
                                    </p>
                                    </section>
                                    <p>
                                        Komunitas atau supporter sepak bola melakukan registrasi kepada klub sepak bola dan mendapatkan informasi mengenai alamat private DWA Node atau GAWANG.
                                        Klub sepakbola membangun private DWA Node/GAWANG untuk dijadikan penampungan CST.
                                    </p>
                                    <section id="Padatahap2">
                                        <b>
                                             Pada Tahap 2
                                        </b>
                                    <p>
                                        Komunitas Suporter atau supporter sepak bola melakukan registrasi kepada exchanger untuk mendapatkan individual wallet. 
                                    </p>
                                    </section>
                                    <section id="Padatahap3">
                                        <b>
                                            Pada Tahap 3
                                        </b>
                                    <p>
                                        Komunitas atau Suporter sepak bola melakukan pembelian CST melalui exchanger. 
                                    </p>
                                    </section>
                                    <section id="Padatahap4">
                                        <b>
                                            Pada Tahap 4
                                        </b>
                                    <p>
                                        Komunitas atau Suporter sepak bola melakukan penarikan CST menuju GAWANG.
                                    </p>
                                    </section>
                                    <section id="Padatahap5">
                                        <b>
                                            Pada Tahap 5
                                        </b>
                                    <p>
                                        Klub sepak bola melakukan verifikasi terhadap data komunitas atau supporter sepak bola yang akan menerima point reward. Setelah verifikasi telah berhasil maka point reward akan dikirimkan kepada Komunitas atau supporter sepak bola bersangkutan.
                                    </p>
                                    </section>
                                </li>
                                </section>
                                <section id="TOKENOMIC">
                                <li className="text-justify pr-50"><b>	TOKENOMIC</b>
                                </li>
                                <table>
                                    <tr>
                                        <td></td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>%</td>
                                        <td style={{
                                            textAlign: "right",
                                        }} ><b>CST</b></td>
                                        <td><b>Account</b></td>
                                    </tr>
                                    <tr>
                                        <td>Private Sale</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>5</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>500,000</td>
                                         <td><a href="https://explorer.dwa.network/account-detail/13767407869537018389" target="_blank" >13767407869537018389</a></td>
                                    </tr>
                                    <tr>
                                        <td>Presale</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>15</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/17231925578610464334" target="_blank" >17231925578610464334</a></td>
                                    </tr>
                                    <tr>
                                        <td>DEX</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>15</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/17321376362252359989" target="_blank" >17321376362252359989</a></td>
                                    </tr>
                                    <tr>
                                        <td>CEX</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>30</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>3,000,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/16430889077541746990" target="_blank" >16430889077541746990</a></td>
                                    </tr>
                                    <tr>
                                        <td>Marketing & Airdrop</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>15</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/16282243540608621003" target="_blank" >16282243540608621003</a></td>
                                    </tr>
                                    <tr>
                                        <td>Operational & Legal</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>5</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/1975524097946072881" target="_blank" >1975524097946072881</a></td>
                                    </tr>
                                    <tr>
                                        <td>R&D and collecting information</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>5</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>500,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/552218604543612285" target="_blank" >552218604543612285</a></td>
                                    </tr>
                                    <tr>
                                    <td>Reserve</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>10</td>
                                        <td style={{
                                            textAlign: "right"
                                        }}>1,000,000</td>
                                        <td><a href="https://explorer.dwa.network/account-detail/15938470025487017346" target="_blank" >15938470025487017346</a></td>
                                </tr>
                                <tr>
                                    <td><b>Total Supply</b></td>
                                        <td style={{
                                            textAlign: "right"
                                        }}><b>100</b></td>
                                        <td style={{
                                            textAlign: "right"
                                        }}><b>10,000,000</b></td>
                                </tr>
                            </table>
                            <br></br>
                                <span>Kunjungi</span> <a href="https://csttoken.net/explorer" target="_blank" >https://csttoken.net/explorer</a>
                            <br></br>
                            <br></br>              
                            <img src={require("../../image/tokenomic.png")} />
                            <p>
                                <b>
                                    Catatan:
                                </b>   
                            </p>
                            <p>
                            1.	Kami telah mengumpulkan dan menyiapkan Rp 5.000.000.000,00 (lima miliar rupiah) untuk memenuhi pengeluaran tim inti 6 bulan kedepan, pengembangan untuk fitur percobaan, marketing untuk menjalankan campaign, dan tahap pre-sale pertama.
                            </p>
                            <p>
                            2.	15% dari total supply dialokasikan untuk marketing / Sponsorship. Diharapkan nilai ini akan mencakup target market 18 klub sepak bola liga 1 Indonesia.
                            </p>
                        </section>
                        <section id="Roadmap">
                            <li className="text-justify pr-50"><b>	Roadmap</b>
                            </li>
                            <img src={require("../../image/roadmap.png")} />
                        </section>
                        <section id="TeamKami">
                            <li className="text-justify pr-50"><b>	Team Kami</b>
                            </li>
                            <p>
                            •	Founder			: Desi Yati
                            </p>
                            <p>
                            •	CEO & Co-Founder	: Randy Andy
                            </p>
                        </section>
                        <section id="PartnerKami">
                            <li className="text-justify pr-50"><b>	Partner Kami</b>
                            </li>
                            <p>
                                Dewa United, Dewa United Football Club atau disingkat sebagai Dewa United FC merupakan sebuah klub sepak bola Indonesia yang didirikan pada tanggal 22 Februari 2021. Klub yang berjuluk Deluxe Unicorn ini menjadi klub yang membawa nama Tangerang Selatan di ajang Sepakbola Indonesia. Untuk saat ini Dewa United memiliki merchandise yang dijual pada official store maupun di marketplace
                            </p>
                            <li className="text-justify pr-50"><b>	Kesimpulan</b>
                            </li>
                            <p>
                                Tim TSD merupakan terdiri dari professional dalam bidang nya untuk mengembangkan CST sehingga dapat membantu masyarakat Indonesia mengenalkan penggunaan system kripto, dikarenakan aset kripto bukan hanya konsep baru dalam segi aset, akan tetapi dari sisi investasi menjadi hal yang baru bagi masyarakat.
                            </p>
                            <p>
                                Melalui TSD maka CST akan mengembangkan system kripto yang efisien dan mudah digunakan. TSD akan menyempitkan atau mempermudah koneksi antara komunitas supporter bola dengan klub bola terkait. Dan tidak menutup kemungkinan TSD akan merambah ke komunitas-komunitas lainnya.
                            </p>
                        </section>
                    </ol>
                        </div>
                        <div className="col-md border-start">
                            <div className="sidebar">
                                <p>PADA HALAMAN INI</p>
                                <ul>
                                    <ListMenu />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
